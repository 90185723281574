@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter";
  }

  .glass {
    background: rgba(255, 0, 0, 0.2);
    border-radius: 5px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  @media print {
    body * {
      visibility: hidden;
    }
    #printable,
    #printable * {
      visibility: visible;
    }
    #printable {
      position: fixed;
      left: 0;
      top: 0;
    }
  }
  .indicator-shadow {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0.5px 5px;
  }
}
